import { createWebHistory, createRouter } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomePage')
    },
    {
        path: '/address',
        name: 'AddressForm',
        component: () => import('@/views/AddressForm')
    },
    {
        path: '/history',
        name: 'HistorySearch',
        component: () => import('@/views/HistorySearch')
    },
    {
        path: '/paySuccess',
        name: 'PaySuccess',
        component: () => import('@/views/PaySuccess')
    },
    {
        path: '/productView',
        name: 'ProductView',
        component: () => import('@/views/ProductView')
    }
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

export default router
