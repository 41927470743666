import { createApp } from 'vue'
import App from './App.vue'
//引入路由
import router from './router'
// 引入组件样式
import Vant from 'vant';
import 'vant/lib/index.css';

const app = createApp(App)

app.use(Vant)
app.use(router)
app.mount('#app')
